
$(function() {
	$(".wcff_cart_editor_field").click(function() { 
	    $(this).click(); 
	    $(this).click(); 
	});
});

// Ajout d'une classe sur le body en cas d'ouverture du toggle
$('.navbar-toggler').click(function() {
	btn = $('.navbar-toggler');
	if($('body').hasClass('navbar_opened')) {
		$('body').removeClass('navbar_opened');
	}
	else {
		$('body').addClass('navbar_opened');
	}
	if($('.navbar-collapse').hasClass('show')) {
		$('.navbar-collapse').css('transition', '0.5s');
		$('.navbar-collapse').css('transform', 'translateX(100%)');
	} else {
		$('.navbar-collapse').css('transition', '0.5s');
		$('.navbar-collapse').css('transform', 'translateX(0%)');
	}
	window.setTimeout(function(){ 
        btn.prop('disabled',true);
    },50);
	window.setTimeout(function(){ 
        btn.prop('disabled',false);
    },750);
});


$(document).ready(function() {

    if($(".accueil_boutique").length) {

    	window.setTimeout(function(){ 
		    $('#modal_event').addClass('show');
		    $('#modal_event').css("display","block");

		    $('#modal_event .close').on('click', function() {
		    	$('#modal_event').removeClass('show');
		    	$('#modal_event').css("display","none");
		    });
	    },750);
	}

	if($(".woocommerce-checkout").length) {
		const livraisons = document.querySelectorAll('#order_review .variation-Mthodedelivraison');
		let nbEmail = 0;
		let nbPostal = 0;

		livraisons.forEach(livraison => {
			if(livraison.innerText.includes('email')) {
				nbEmail++;
			}
			if(livraison.innerText.includes('courrier')) {
				nbPostal++;
			}
		});
		
		if(nbPostal == 0) {
			const shipToDIfferent = document.querySelector('#customer_details .woocommerce-shipping-fields');
			shipToDIfferent.style.display = 'none';
		}
	}


	// MANAGE VARIATIONS
	const variationSelect = document.querySelector('table.variations select#montant');
	if(variationSelect) {

		variationSelect.addEventListener('change', function() {

			hideLines();
			setTimeout(() => {
				retryHideLoader();
			}, 100);
			
		});

		let retries = 0;
		const maxRetries = 10;
		const interval = 100;
		const startTime = Date.now();

		const retryHideLoader = () => {
			const loader = document.querySelector('.woo-loder-wrap');
			if (loader.style.display === 'none') {
				hideLines();
			} else {
				setTimeout(retryHideLoader, interval);
			}
		}

		setTimeout(() => {
			console.log('Initial hideLines()');
			
			retryHideLoader();
		}, 500);
		
		// hideLines();

		function hideLines() {
			const methodBlocks = $('.woo-vou-recipient-delivery-method');	
			methodBlocks.each(function() {
				const isEnabled = $(this).find('tr.woo-vou-delivery-method-wrapper input').prop('checked');
	
				if(!isEnabled) {
					const retryHideLines = () => {
						const otherLines = $(this).find('tr:not(.woo-vou-delivery-method-wrapper)');
						if (otherLines.length > 0) {
							otherLines.each(function () {
								// console.log('Other line : ', $(this));
								$(this).css('display', 'none');
								// $(this).addClass('hidden');
							});
							console.log('Lines hidden successfully.');
						} else {
							console.log('Failed to find otherLines after retries.');
						}
					};
	
					retryHideLines();
				}
			});
		}
		hideLines();
	}

	// Gestion du checkout en cas de commande à emporter
	// if($(".woocommerce-checkout-review-order dl.variation").length) {

    // 	console.log('COMMANDE À EMPORTER DETECTÉE !');

	// 	$.each($('.woocommerce-shipping-fields .input-text'), function (indexInArray, valueOfElement) { 
	// 		 $(this).val('');
	// 	});

	// 	$('#shipping_first_name').val('Commande a emporter');
	// 	$('#shipping_postcode').val('00000');

	// 	$('.woocommerce-shipping-fields, .woocommerce-additional-fields').addClass('hidden');
	// }

});